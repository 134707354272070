import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../img/wibime_dev_logo.png";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

class Navigation extends Component {
  render() {
    return (
      <div className="container-fluid p-0 m-0">
        <Navbar
          className="px-5 text-dark fixed-top"
          collapseOnSelect
          expand="lg"
          bg="light"
        >
          <Navbar.Brand as={Link} to="/">
            <img
              className="d-none d-lg-inline-block d-xl-inline-block"
              src={Logo}
              alt="logo"
              width="300"
            />
            <img
              className="d-inline-block d-lg-none d-xl-none"
              src={Logo}
              alt="logo"
              width="200"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/" className="mx-2">
                HOME
              </Nav.Link>
              <Nav.Link as={Link} to="/portfolio" className="mx-2">
                PORTFOLIO
              </Nav.Link>
              <Nav.Link as={Link} to="/about" className="mx-2">
                ABOUT ME
              </Nav.Link>
              <Nav.Link as={Link} to="/contact" className="mx-2">
                CONTACT ME
              </Nav.Link>
              <Nav.Link
                href="https://helpdesk.wibime.dev"
                target="blank"
                className="mx-2"
              >
                HELPDESK
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Nav className="mx-auto">
            <Nav.Link className="mx-2" href="tel:+972545305202">
              Call me
            </Nav.Link>
            <Nav.Link className="mx-2" href="mailto:izil123@gmail.com">
              Email me
            </Nav.Link>
          </Nav>
        </Navbar>
        <nav className="navbar fixed-bottom navbar-light text-dark bg-light">
          <div className="text-center mx-auto">
            © 2021 All rights reserved to Izidor Levy
          </div>
        </nav>
      </div>
    );
  }
}

export default Navigation;
