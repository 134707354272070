import React, { Component } from "react";
import easystoreImg from "../img/portfolio/easystore.png";
import easyvacationsImg from "../img/portfolio/easyvacations.png";
import cryptoniteImg from "../img/portfolio/cryptonite.png";
import notesImg from "../img/portfolio/notes.png";
import dljdImg from "../img/portfolio/dljd.png";
import cmcImg from "../img/portfolio/cmc.png";
import nichartImg from "../img/portfolio/nichart.png";

class Portfolio extends Component {
  projects = [
    {
      img: easystoreImg,
      name: "EasyStore",
      desc: "Online market store including client and admin interfaces. Frontend is based on Angular and Backend is based on NodeJs.",
      git: "",
      link: "https://easystore.wibime.dev",
    },
    {
      img: easyvacationsImg,
      name: "EasyVacations",
      desc: "Online vacation selection system including client and admin interfaces. Frontend is based on ReactJs and Backend is based on NodeJs.",
      git: "",
      link: "https://easyvacations.wibime.dev",
    },
    {
      img: cryptoniteImg,
      name: "Cryptonite",
      desc: "Online crypto currency information and monitoring system. Based on Javascript ES6, including AJAX and jQeury.",
      git: "",
      link: "https://cryptonite.wibime.dev",
    },
    {
      img: notesImg,
      name: "Notes & Tasks",
      desc: "Online notes and tasks system. Based on Javascript ES6, Html, CSS and localstorage.",
      git: "",
      link: "https://notes.wibime.dev",
    },
    {
      img: dljdImg,
      name: "Dana Levy Jewelry Design",
      desc: "Online jewelry designer website. Based on ReactJs.",
      git: "",
      link: "https://www.dl-jewelry.com",
    },
    {
      img: cmcImg,
      name: "CheckMeCard.com",
      desc: "Online digital presence card / business card. Frontend is based on Angular and Backend is based on NodeJs.",
      git: "",
      link: "https://www.CheckMeCard.com",
    },
    {
      img: nichartImg,
      name: "NichArt.com",
      desc: "Online Unique Home Design Art Creator. Based on Javascript ES6, PHP, Html and CSS.",
      git: "",
      link: "https://www.NichArt.com",
    },
  ];
  render() {
    let projectsCards = this.projects.map((project, i) => {
      return (
        <div className="card col-md-6 border-0 mb-3" key={i}>
          <img src={project.img} alt={project.name} />
          <div className="card-body">
            <h5 className="card-title">{project.name}</h5>
            <p className="card-text">{project.desc}</p>
            <p className="card-text">
              <a href={project.git} target="blank">
                Link to GitHub
              </a>
            </p>
            <p className="card-text">
              <a href={project.link} target="blank">
                Link to project
              </a>
            </p>
          </div>
        </div>
      );
    });
    return (
      <div className="container p-5">
        <h4 className="mb-4">Some of my projects:</h4>
        <h5 className="mb-4">
          <a href="https://github.com/izilevy" target="blank">
            Visit my GitHub profile
          </a>
        </h5>
        <div className="row">{projectsCards}</div>
      </div>
    );
  }
}

export default Portfolio;
