import React, { Component } from "react";
import code from "../img/welcome/code.jpg";
import fullstack from "../img/welcome/fullstack.png";
import website from "../img/welcome/website.png";
import Angular from "../img/welcome/Angular.png";
import Bootstrap from "../img/welcome/Bootstrap.png";
import CSS3 from "../img/welcome/CSS3.png";
import Git from "../img/welcome/Git.png";
import HTML5 from "../img/welcome/HTML5.png";
import Javascript from "../img/welcome/Javascript.png";
import Materialize from "../img/welcome/Materialize.png";
import Mongodb from "../img/welcome/Mongodb.png";
import MySQL from "../img/welcome/MySQL.png";
import NodeJs from "../img/welcome/NodeJs.png";
import Php from "../img/welcome/Php.png";
import ReactImg from "../img/welcome/React.png";
import Sass from "../img/welcome/Sass.png";
import Vue from "../img/welcome/Vue.png";

class Welcome extends Component {
  tech = [
    { logo: HTML5, name: "HTML5", desc: "" },
    { logo: CSS3, name: "CSS3", desc: "" },
    { logo: Javascript, name: "Javascript", desc: "" },
    { logo: Php, name: "Php", desc: "" },
    { logo: Sass, name: "Sass", desc: "" },
    { logo: ReactImg, name: "ReactJs", desc: "" },
    { logo: Angular, name: "Angular", desc: "" },
    { logo: Vue, name: "Vue", desc: "" },
    { logo: NodeJs, name: "NodeJs", desc: "" },
    { logo: Bootstrap, name: "Bootstrap", desc: "" },
    { logo: Materialize, name: "Materialize", desc: "" },
    { logo: MySQL, name: "MySQL", desc: "" },
    { logo: Mongodb, name: "Mongodb", desc: "" },
    { logo: Git, name: "Git", desc: "" },
  ];
  render() {
    let techCards = this.tech.map((tech, i) => {
      return (
        <div
          className="card col-lg-3 col-md-4 col-sm-6 border-0"
          width="540"
          key={i}
        >
          <div className="row g-0">
            <div className="col-md-4 p-1">
              <img height="80" src={tech.logo} alt={tech.name} />
            </div>
            <div className="col-md-8 p-2">
              <div className="card-body">
                <h5 className="card-title">{tech.name}</h5>
                <p className="card-text">{tech.desc}</p>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        <div className="carousel-inner">
          <div>
            <img className="d-block w-100 imgFull opac" src={code} alt="code" />
            <h1 className="carousel-caption text-dark fw-bolder overlay d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">
              Created: for && according to === your.needs;
            </h1>
            <h3 className="carousel-caption text-dark fw-bolder overlay d-none d-sm-inline-block d-md-none d-lg-none d-xl-none">
              Created: for && according to === your.needs;
            </h3>
            <h5 className="carousel-caption text-dark fw-bolder overlay d-xs-inline-block d-sm-none d-md-none d-lg-none d-xl-none">
              Created: for && according to === your.needs;
            </h5>
          </div>
        </div>
        <div className="container p-5">
          <div className="row">
            <div className="col-md-6 mb-3 p-3">
              <h3>Web application development</h3>
              <p>
                Need to create and develop a web application for your company or
                businees?
                <br />
                No problem!
                <br />I am a full-stack web developer that can give you the
                answer for both front-end and back-end. While keeping the
                Javascript, CSS and HTML in shape, I will develop you the app
                you need using different frameworks and methods which will suit
                your project perfectly.
                <br />I will keep your back-end on Node.Js and will give you the
                complete package for the app config.
              </p>
            </div>
            <div className="col-md-6 text-center mb-3">
              <img className="imgFull" src={fullstack} alt="fullstack" />
            </div>
            <div className="col-md-6 text-center mb-3">
              <img className="imgFull" src={website} alt="website" />
            </div>
            <div className="col-md-6 mb-3 p-3">
              <h3>Simple website</h3>
              <p>
                Need to create and develop a simple website for your company or
                businees?
                <br />
                I do this also! <br />
                By having the full-stack tools, I am able to create you great
                looking responsive website which will give complete promotional
                effect you need as per your design and content requests.
              </p>
            </div>
            <div className="col-md-12">
              <h4>Some of the technologies I work with:</h4>
            </div>
            {techCards}
          </div>
        </div>
      </div>
    );
  }
}

export default Welcome;
