import React, { Component } from "react";
import { Link } from "react-router-dom";
import img from "../img/about/izi.jpg";

class About extends Component {
  render() {
    return (
      <div className="container p-5">
        <div className="row">
          <div className="col-md-9">
            <h1>Hi!</h1>
            <p>
              I'm Izidor.
              <br />A web developer.
            </p>

            <p>
              My name is Izidor, but everyone calls me Izi (feel free to do so
              also 😊).
              <br />I am a junior full-stack web developer.
              <br />I have a great passion in creation and development.
              <br />I love learning new technologies and techniques.
              <br />
              My main base guideline is that learning never stops.
              <br />
              I will be happy to have the chance and opportunity to supply your
              needs and help you achieve the goal you are looking for in your
              next project.
              <br />
              Let's go together hand in hand towards the completion of your next
              milestone in your business roadmap.
              <br />I am available for any of your question, request and inquiry
              via the <Link to="/contact">contact page</Link> or the{" "}
              <a href="https://helpdesk.wibime.dev" target="blank">
                helpdesk system
              </a>
              .
              {/* <br />
          As a family man with a daughter in her first steps at school, I feel
          the need to pass her the understanding that we are learning new things
          every day. */}
            </p>
          </div>
          <div className="col-md-3">
            <img
              src={img}
              alt="me"
              id="myPicture"
              height="100%"
              className="img-thumbnail"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default About;
