/* eslint-disable default-case */
const initialState = {
  games: [],
  football: [],
  basketball: [],
  categories: [],
  comments: [],
};

function rootReducer(state = initialState, action) {
  console.log("Root: ", action);
  switch (action.type) {
    case "updateGames":
      state = { ...state, games: action.payload };
      break;
    case "updateFootball":
      state = { ...state, football: action.payload };
      break;
    case "updateBasketball":
      state = { ...state, basketball: action.payload };
      break;
    case "updateComments":
      state = { ...state, comments: action.payload };
      break;
  }
  return state;
}

export default rootReducer;
